import { Injectable } from '@angular/core';
import { createState, setProp, setProps, Store, withProps } from '@ngneat/elf';

import { CSAType, CustomizeStockArt, CustomizeStockArtDetailSection, CustomizeStockArtSections, ID, ShapeProperty } from '@graphics-flow/types';

export interface CustomizeStockArtState {
  customizeStockArt?: CustomizeStockArt;
  previewUrl?: string;
  isPreviewUpdate?: boolean;
  selectedLayerPosition?: string;
  ui: {
    detail: {
      activeSection: string;
      activeIndex: number;
      detailActiveSection: CustomizeStockArtDetailSection;
    };
  };
  saveDesignInFolderID?: ID;
  CSAType?: CSAType;
  shapeIds?: number[];
  shapeProperty?: ShapeProperty;
  boundingBox?: boolean;
  loading: boolean
}

export const initialCustomizeStockArtState = {
  ui: {
    detail: {
      activeSection: CustomizeStockArtSections.HOME,
      activeIndex: -1,
      detailActiveSection: CustomizeStockArtDetailSection.TEXT_DETAIL
    }
  },
  loading: false
};

const { state, config } = createState(
  withProps<CustomizeStockArtState>(initialCustomizeStockArtState)
);

@Injectable({ providedIn: 'root' })
export class CustomizeStockArtStore extends Store {

  constructor() {
    super({ name: 'customizeStockArt', state, config });
  }

  updateCustomizeStockArt(csa: CustomizeStockArt) {
    this.update(setProps((state) => {
      state.customizeStockArt = csa;
      return state;
    }));
  }

  setActiveSection(section: CustomizeStockArtSections, activeIndex: number = -1) {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        detail: {
          ...state.ui.detail,
          activeSection: section,
          activeIndex: activeIndex
        }
      }
    }));
  }

  setDetailActiveSection(section: CustomizeStockArtDetailSection) {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        detail: {
          ...state.ui.detail,
          detailActiveSection: section
        }
      }
    }));
  }

  setPreviewUrl(url: string) {
    this.update(setProps({ previewUrl: url }));
  }

  setIsPreviewUpdate(isPreviewUpdate: boolean) {
    this.update(setProps({ isPreviewUpdate: isPreviewUpdate }));
  }

  updateSaveDesignInFolderID(folderId: ID): void {
    this.update(setProps({ saveDesignInFolderID: folderId }));
  }

  setActiveIndex(index: number): void {
    this.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        detail: {
          ...state.ui.detail,
          activeIndex: index
        }
      }
    }));
  }

  setLayerPosition(layerPosition: string): void {
    this.update(setProps({ selectedLayerPosition: layerPosition }));
  }

  setLoading(loading: boolean): void {
    this.update(setProp('loading', loading ));
  }
}
