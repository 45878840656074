import { Component, Input } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';
import { OrganizationQuery } from '@graphics-flow/util';
import { Organization } from '@graphics-flow/types';
import { OrganizationActionsService } from '../../services/organization-actions.service';

@Component({
  selector: 'gf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  date: Date = new Date();
  organization: Organization = this.organizationQuery.getActive();

  // used to hide the privacy & terms of use.
  @Input() blankFooter = false;
  @Input() organizationName = '';

  constructor(readonly translations: Translations,
              public readonly organizationActionService: OrganizationActionsService,
              public readonly organizationQuery: OrganizationQuery) {
  }

}
