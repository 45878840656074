import { formatNumber } from '@angular/common';
import { ArtDetailSections, BundlePlanType, ID, Organization, StockArtDetailSections } from '@graphics-flow/types';
import { ProPlans } from '../constants/Plans.constants';
import { MAX_SCROLL_LIMIT } from '../constants/infinity-scroller.constants';

type ItemPredicate<Item = any> = (item: Item, index?: number) => boolean;
export class GlobalHelpers {
  static readonly SUPPORTED_FILE_EXTENSIONS = ['.ai', '.cdr', '.dst', '.eps', '.gif', '.jpeg', '.jpg', '.pdf', '.png', '.ps', '.psd', '.svg', '.tif', '.tiff'];
  static readonly NON_IMAGE_FILE_EXTENSIONS = ['.csv', '.docx', '.draw', '.emb', '.exp', '.pes', '.xls', '.zip'];
  static readonly SUPPORTED_MY_ART_FILE_EXTENSIONS = [...GlobalHelpers.SUPPORTED_FILE_EXTENSIONS, ...GlobalHelpers.NON_IMAGE_FILE_EXTENSIONS];
  static readonly SUPPORTED_CSA_FILE_EXTENSIONS = ['.gif', '.png', '.jpeg', '.jpg', '.pdf', '.svg', '.cdr', '.ai', '.ps', '.psd', '.tif', '.tiff', '.eps'];
  static readonly EMAIL_REGEXP = /^(\?("")("".+?(?:!\\)""@)|(([0-9a-zA-Z]((\.(?!\.))|[-!#\$%&'\*\+\/=\?\^`\{\}\|~\w])*)(?:[0-9a-zA-Z])@))(\?(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-Z][-0-9a-zA-Z]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9][\-a-zA-Z0-9]{0,22}[a-zA-Z0-9]))$/;
  static readonly PASSWORD_REGEXP = new RegExp(/.{10,}/);
  static readonly PHONE_REGXP = /^[\s()+-]*([0-9][\s()+-]*){5,20}(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
  static readonly FILE_SIZE_LIMIT = 52428800; // (1024 * 1024) * 50 = 50 MB;
  static readonly ONE_MB = 1048576;
  static readonly ONE_GB = 1073741824;
  static readonly ONE_HUNDRED_MB = 104857600;
  static readonly ONE_TB = 1099511627776;
  static readonly GRAPHICS_FLOW = {
    TELL: '800-959-7627',
    SUPPORT_EMAIL: 'help@graphicsflow.com'
  };
  static readonly WEBSITE_REGEXP = /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  // regex for email/email
  static readonly EMAIL_REGEXP_WITH_SLASH = /^(\?("")("".+?(?:!\\)""@)|(([0-9a-zA-Z]((\.(?!\.))|[-!#\$%&'\*\+\/=\?\^`\{\}\|~\w])*)(?:[0-9a-zA-Z])@))(\?(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-Z][-0-9a-zA-Z]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9][\-a-zA-Z0-9]{0,22}[a-zA-Z0-9]))((\/| \/|\/ | \/ |){1}(\?("")("".+?(?:!\\)""@)|(([0-9a-zA-Z]((\.(?!\.))|[-!#\$%&'\*\+\/=\?\^`\{\}\|~\w])*)(?:[0-9a-zA-Z])@))(\?(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-Z][-0-9a-zA-Z]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9][\-a-zA-Z0-9]{0,22}[a-zA-Z0-9]))){0,1}$/;

  static isIOS = /(iPod|iPhone|iPad)/.test(navigator.userAgent);

  static isMacOS = /(Mac)/.test(navigator.userAgent);

  static GetFileNameWithOutExt(filename: string) {
    if (!filename) {
      return;
    }
    return filename.substr(0, filename.lastIndexOf('.')) || filename;
  }

  public static copyToClipboard(value: string) {
    const txtArea = document.createElement('textarea');
    txtArea.style.position = 'fixed';
    txtArea.style.left = '0';
    txtArea.style.top = '0';
    txtArea.style.opacity = '0';
    txtArea.value = value;
    document.body.appendChild(txtArea);
    txtArea.focus();
    txtArea.select();
    document.execCommand('copy');
    document.body.removeChild(txtArea);
  }

  public static validateEmail(email: string): boolean {

    if (email === undefined || email === null || email.length === 0) {
      return false;
    }
    return (GlobalHelpers.EMAIL_REGEXP.test(email));
  }

  public static isDate(value: any): boolean {
    return value !== undefined && value !== null && !!value?.getMonth;
  }

  public static addTimeToUrl(url: string): string {
    return `${url}?time=${Date.now()}`;
  }

  public static getBorderColor(hex: string): string {
    if (!hex || (hex.toLowerCase() === '#ffffff')) {
      return '#b7bdc3';
    } else if (GlobalHelpers.getContrastColorValue(hex) <= 0.12 ) {
      return '#E0E0E0';
    } else {
      return hex;
    }
  }

  static getContrastColorValue($color): number {
    if ($color) {
      let c = $color.substring(1); // strip #
      // cases like '#BBB'
      if (c.length === 3) {
        c = c.charAt(0) + c.charAt(0) + c.charAt(1) + c.charAt(1) + c.charAt(2) + c.charAt(2);
      }
      const rgb = parseInt(c, 16); // convert rrggbb(hex) to decimal
      const r = (rgb >> 16) & 0xff; // extract red
      const g = (rgb >> 8) & 0xff; // extract green
      const b = (rgb >> 0) & 0xff; // extract blue
      // Bright colors return black. Dark colors return white.
      return 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    }
    return 0;
  }

  public static isFileLessThan50MB(file: File) {
    if (!file) {
      return;
    }
    return file?.size <= GlobalHelpers.FILE_SIZE_LIMIT;
  }

  public static formatDecimals(value: number, decimals: number): number {
    const valuesArray = value.toString().split('.');

    if (!valuesArray.length) {
      return;
    }

    if (valuesArray.length === 2) {
      return Number([valuesArray[0], valuesArray[1].slice(0, decimals)].join('.'));
    }
    return Number(valuesArray[0]);
  }

  // Note:- We are using this function only to show the units in usage-warning card comp due to Design requirements.
  // Rest of the places we are using only fileSize pipe from ngx-filesize.
  public static fileSizeWithUnits(usage: number): string {
    if (usage === 0) {
      return '0 kb';
    }

    if (usage < this.ONE_MB) {
      return this.formatDecimals(usage / 1024, 1) + ' KB';
    }

    if (usage < this.ONE_HUNDRED_MB) {
      return this.formatDecimals(usage / this.ONE_MB, 1) + ' MB';
    }

    if (usage < this.ONE_TB) {
      return this.formatDecimals(usage / this.ONE_GB, 1) + ' GB';
    }

    return this.formatDecimals(usage / this.ONE_TB, 1) + ' TB';
  }

  // will return TRUE, If the given element is inside the viewport & vice versa.
  public static isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
  }

  public static isElementReachedTop(el, offset: number = 0) {
    const rect = el?.getBoundingClientRect();
    return (rect?.top + offset) <= 0;
  }

  public static buildPublicCatalogURL(org: Organization): string {
    return window.origin + '/art-portal/' + GlobalHelpers.buildOrganizationNameForUrl(org?.name) + '/' + org?.organizationId;
  }

  public static buildOrganizationNameForUrl(orgName: string): string {
    // To remove all the special characters from organization name
    let name: string = orgName?.replace(/[^a-zA-Z0-9 ]/g, '');
    // now replacing space with underscore
    name = name?.replace(/ /g, '_').toLowerCase();
    return name;
  }

  public static buildArtPortalCanonicalUrl(orgName: string, orgId: string): string {
    return window.origin + '/art-portal/' + GlobalHelpers.buildOrganizationNameForUrl(orgName) + '/' + orgId;
  }

  public static buildPublicCatalogURLForEmbed(org: Organization) : string {
    return window.origin + '/art-portal/' + GlobalHelpers.buildOrganizationNameForUrl(org.name) + '/' + org.organizationId + '?view=embed';
  }

  public static getInfiniteScrollValidLimit(index: number, baseLimit: number): number {
    const nextIndexCount: number = index + baseLimit;
    if (nextIndexCount > MAX_SCROLL_LIMIT) {
      return MAX_SCROLL_LIMIT - index;
    }
    return baseLimit;
  }

  public static getExtensionWithoutDot(extension: string): string {
    return extension?.startsWith('.') ? extension.substring(1) : extension;
  }

  public static getNullForEmptyString(value: string): string {
    return value?.length ? value : null;
  }

  public static getValidQueryParamForDetailView(activeSection: StockArtDetailSections, isMyArtDetailView: boolean = false): StockArtDetailSections | ArtDetailSections {
    switch (activeSection) {
      case StockArtDetailSections.INFO || ArtDetailSections.INFO:
        return StockArtDetailSections.INFO;
      case StockArtDetailSections.CUSTOMIZE || ArtDetailSections.CUSTOMIZE:
        return StockArtDetailSections.CUSTOMIZE;
      case StockArtDetailSections.STOCK_ART_CUSTOMIZATIONS:
        // When we are in my art detail view, we should not allow user to navigate to "STOCK_ART_CUSTOMIZATIONS",
        // because in my art we don't have that option. so we are just returning the default type "INFO".
        return isMyArtDetailView
          ? StockArtDetailSections.INFO
          : StockArtDetailSections.STOCK_ART_CUSTOMIZATIONS;
      default:
        return StockArtDetailSections.INFO;
    }
  }

  public static getPreviousUrl(url: string): string {
    if (!url) {
      return '';
    }

    if (url.includes('activeSection') || url.includes('filters')) {
      return url?.split('?')[0];
    }

    return url;
  }

  public static getTotalResultFormat(totalResults: number, locale: string): string {
    if (!totalResults) {
      return '0';
    } else if (totalResults === 10000) {
      return formatNumber(10000, locale) + '+';
    }
    return formatNumber(totalResults, locale);
  }

  public static isProPlan(plan: BundlePlanType): boolean {
    return ProPlans.includes(plan);
  }

  public static textCapitalize(text: string): string {
    return text && text?.charAt(0)?.toUpperCase() + text?.slice(1);
  }

  public static arrayRemove<T>(currentValue: T | T[], newValue: T | T[]): T[] {
    const itemsToBeRemoved: T[] = GlobalHelpers.coerceArray(newValue);
    const existingItems: T[] = GlobalHelpers.coerceArray(currentValue);
    return existingItems.filter((current) => itemsToBeRemoved.includes(current) === false);
  }

  private static coerceArray<T>(value: T | T[]): T[] {
    if (value === null || value === undefined) {
      return [];
    }
    return Array.isArray(value) ? value : [value];
  }

  public static arrayAdd<T>(currentValue: T | T[], newValue: T | T[], prepend: boolean = false): T[] {
    const itemsToBeAdded: T[] = GlobalHelpers.coerceArray(newValue);
    const existingItems: T[] = GlobalHelpers.coerceArray(currentValue);

    return prepend ? [...itemsToBeAdded, ...existingItems] : [...existingItems, ...itemsToBeAdded];
  }

  public static getProperSortByKey<T>(sortBy: string): string | ((val: T) => string) {
    if (!['createdDate', 'modifiedDate'].includes(sortBy)) {
      return (val: T) => val[sortBy]?.toLowerCase();
    }

    return sortBy;
  }
}
