import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep as _cloneDeep, isEqual as _isEqual, isUndefined as _isUndefined, uniq as _uniq } from 'lodash-es';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Translations } from '@graphics-flow/shared/assets';
import { ApiResponse, Art, ArtType, Folder, ID, MyArtActiveQueryParams, NotificationType, Order, Tag, UserSettings } from '@graphics-flow/types';
import { NotificationService } from 'shared/ui';

import { MAX_SCROLL_LIMIT } from '../../constants/infinity-scroller.constants';
import { GlobalHelpers } from '../../helpers/global.helpers';
import { GraphicsFlowService } from '../../services/graphics-flow.service';
import { ArtService } from '../art/art.service';
import { FolderService } from '../folder/folder.service';
import { MyArtBulkSelectionService } from '../my-art-bulk-selection/my-art-bulk-selection.service';
import { OrganizationQuery } from '../organization/organization.query';
import { UserQuery } from '../user/user.query';
import { MyArtSearchQuery } from './my-art-search.query';
import { MyArtSearchState, MyArtSearchStore } from './my-art-search.store';

@Injectable({ providedIn: 'root' })
export class MyArtSearchService {
  destroyed$ = new Subject();
  clearSearchControl$: Subject<undefined> = new Subject();
  isRefreshArtSection = false;
  // When the global search field or local search field is focused.
  // The Delete key should not trigger the bulk delete confirmation modal.
  stopDeleteKeyStroke = false;

  constructor(private readonly myArtSearchStore: MyArtSearchStore,
    private readonly myArtSearchQuery: MyArtSearchQuery,
    private readonly router: Router,
    private folderService: FolderService,
    protected artService: ArtService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    public readonly translations: Translations,
    public readonly userQuery: UserQuery,
    private myArtBulkSelectionService: MyArtBulkSelectionService,
    private readonly graphicsFlowService: GraphicsFlowService,
    private organizationQuery: OrganizationQuery) {
  }

  initialize(): void {
    this.myArtSearchQuery.activeQueryParams$.pipe(
      debounceTime(500),
      filter((activeQueryParams) => !!activeQueryParams.sortBy
        && !(this.graphicsFlowService.isDeleteJobInProgress
           && activeQueryParams.trashed)),
      distinctUntilChanged((oldFilter, newFilter) =>  {
        // Hack to remove "__proto__" from the objects
        // TODO: find a cleaner way to handle this.
        oldFilter = JSON.parse(JSON.stringify(oldFilter));
        newFilter = JSON.parse(JSON.stringify(newFilter));
        const isNotDistinct = _isEqual(oldFilter, newFilter) && !this.isRefreshArtSection;
        if (isNotDistinct) {
          this.myArtSearchStore.hideLoading();
        }
        return isNotDistinct;
      }),
      switchMap((activeQueryParams) => {
        const loadArtsAndFoldersOverAPI: Observable<ApiResponse<Art[]> | Folder[]>[] = [this.artService.getArts(_cloneDeep(activeQueryParams))];
        if ((activeQueryParams.filterTags.length === 0) && !activeQueryParams.artType && (activeQueryParams.index === 0)) {
          loadArtsAndFoldersOverAPI.push(this.folderService.loadFolders(activeQueryParams))
        }
        // Used to set folders = []; when user applied any filters.
        // Because we should not show folders when these filters are applied.
        if ((activeQueryParams.filterTags.length !== 0) || !!activeQueryParams.artType) {
          loadArtsAndFoldersOverAPI.push(of([]));
        }

        if (!activeQueryParams.index) {
          this.myArtBulkSelectionService.reset();
          this.myArtBulkSelectionService.resetActiveArtIdsInDirectory();
        }
        return combineLatest(loadArtsAndFoldersOverAPI);
      }),
      tap(([response, folders]: [ApiResponse<Art[]>, Folder[]]) => {
        this.isRefreshArtSection = false;
        if (!_isUndefined(folders)) {
          this.myArtSearchStore.setFolders(folders);
        }
        this.myArtSearchStore.setPagination(response.pagination);
        if (response.pagination.index === 0) {
          this.myArtSearchStore.setMyArts(response.data);
        } else {
          this.myArtSearchStore.appendMyArts(response.data);
        }
      }),
      takeUntil(this.destroyed$),
      catchError((err) => {
        this.myArtSearchStore.hideLoading();
        this.notificationService.showNotification(NotificationType.ERROR, err.statusText, err.message);
        return throwError(err);
      })
    ).subscribe();
  }

  searchMyArt(searchString: string): void {
    this.myArtSearchStore.searchMyArt(searchString);
  }

  filterMyArtBySearch(searchString: string): void {
    this.myArtSearchStore.filterMyArtBySearch(searchString);
  }

  updatefilterTags(filterTags: Tag[]): void {
    if (!_isEqual(filterTags, this.myArtSearchQuery.getActiveFilterTags())) {
      this.myArtSearchStore.updatefilterTags(filterTags);
    }
  }

  removeTag(tagId: ID): void {
    const activeTags: Tag[] = this.myArtSearchQuery.getActiveFilterTags();
    const filterTags: Tag[] = activeTags?.filter((tag) => tag.tagId !== tagId);
    this.updateFilterQueryParams(filterTags);
    this.myArtSearchStore.updatefilterTags(filterTags);
  }

  updateSearchFocusStatus(focusStatus: boolean): void {
    this.myArtSearchStore.updateSearchFocusStatus(focusStatus);
  }

  updateFolderId(folderId: ID): void {
    this.myArtSearchStore.updateFolderId(folderId);
  }

  reset(): void {
    this.myArtSearchStore.reset();
  }

  updateFilterQueryParams(tags: Tag[]): void {
    const tagIds: ID[] = tags?.map((tag) => tag.tagId);
    this.router.navigate([], {
      queryParams: {
        filterTags: tagIds.length ? tagIds.toString() : null
      },
      queryParamsHandling: 'merge'
    });
  }

  setMyArtActiveQueryParams(activeQueryParams: MyArtActiveQueryParams, viewAll: boolean = false): void {
    if (!activeQueryParams) {
      return;
    }
    this.myArtSearchStore.setActiveQueryParams(activeQueryParams, viewAll);
  }

  setDefaultMyArtQueryParams(): void {
    const userSettings: UserSettings = this.userQuery.getUserSettings();
    this.myArtSearchStore.setActiveQueryParams(<MyArtActiveQueryParams>
      {
        sortBy: <string>userSettings?.sort?.sortBy,
        orderDesc: userSettings?.sort?.sortByOrder === Order.DESC
      });
  }

  setLoaderState(showOverAllLoader: boolean = false): void {
    this.myArtSearchStore.update((state) => ({
      ...state,
      showOverAllLoader,
      showInfiniteScrollLoader: false
    }));
  }

  setIndex(index: number): void {
    if (index === MAX_SCROLL_LIMIT) {
      this.notificationService.showNotification(NotificationType.WARNING, this.translateService.instant(this.translations.common.warning) ,this.translateService.instant(this.translations.common.infinite_scroll_max_limit_warning_msg));
      return;
    }
    if ((index !== 0) && (index !== this.myArtSearchQuery.getPagination().totalResults)) {
      this.setMyArtActiveQueryParams(<MyArtActiveQueryParams>{ index });
    }
  }

  refreshArtSection(): void {
    this.isRefreshArtSection = true;
    this.setMyArtActiveQueryParams(<MyArtActiveQueryParams>{ index: 0 });
  }

  removeSelectedArtsAndFolders(artIds: ID[], folderIds: ID[]): void {
    this.myArtSearchStore.update((state: MyArtSearchState) => ({
      ...state,
      ...(artIds.length && { artIds: GlobalHelpers.arrayRemove(state.artIds, artIds) }),
      ...(folderIds.length && { folderIds: GlobalHelpers.arrayRemove(state.folderIds, folderIds) })
    }));
  }

  addOrUpdateGivenArtsAndFolders(arts: Art[] = [], folders: Folder[] = []): void {
    this.myArtSearchStore.update((state: MyArtSearchState) => {
      if (arts.length) {
        const artIds: ID[] = arts.map((art) => art.artId);
        state['artIds'] = _uniq(state.artIds.concat(artIds));
      }
      if (folders.length) {
        const folderIds: ID[] = folders.map((folder) => folder.folderId);
        state['folderIds'] = _uniq(state.folderIds.concat(folderIds));
      }
      return {...state};
    });
  }

  getFolders(activeQueryParams: MyArtActiveQueryParams): Observable<Folder[]> {
    // 1. (activeQueryParams.filterTags.length !== 0) - When User filtered based on tag! No Folder should be visible.
    // 2. (activeQueryParams.index !== 0) - Folders Don't have infinite scroll, so initial call is enough.
    if ((activeQueryParams.filterTags.length !== 0) || !!activeQueryParams.artType || (activeQueryParams.index !== 0)) {
      return this.myArtSearchQuery.searchFolders();
    }
    return this.folderService.loadFolders(activeQueryParams);
  }

  setFilterArtType(artType: ArtType): void {
    this.myArtSearchStore.updateFilterArtType(artType);
  }

  updateArtTypeFilterQueryParams(artType: ArtType): void {
    this.router.navigate([], {
      queryParams: {
        filterArtType: artType
      },
      queryParamsHandling: 'merge'
    });
  }

  updateFilterArtType(artType: ArtType = null): void {
    const activeArtType: ArtType = <ArtType>this.myArtSearchStore.getValue().activeQueryParams.artType;
    if (activeArtType !== artType) {
      this.updateArtTypeFilterQueryParams(artType);
      this.setFilterArtType(artType);
    }
  }

  updateIsDeletedFiles(isDeletedFiles: boolean): void {
    this.myArtSearchStore.setActiveQueryParams(<MyArtActiveQueryParams>{
      trashed: isDeletedFiles
    });
  }

  getCurrentArtResultsCount(): number {
    return this.myArtSearchStore.getValue().activeArtsCount;
  }


  setMyArtHeaderHeight(): void {
    // Have to set `--art-header-height` because while deleting all files there is an scroll in delete content section.
    const gfArtHeaderHeight = document?.getElementById('gfArtHeader')?.offsetHeight;
    const doc = document?.documentElement;
    doc.style.setProperty('--art-header-height', `${gfArtHeaderHeight}px`);
  }

  getDeleteModalNote(): string {
    const { billingPlan } = this.organizationQuery.getActive();
    if (GlobalHelpers.isProPlan(billingPlan)) {
      return this.translateService.instant(this.translations.common.my_art_delete_note, {x: 'files'});
    }
    return this.translateService.instant(this.translations.common.my_art_delete_note_for_starter_plan, {x: 'files'});
  }
}
