import { CustomColor, CustomizeStockArtShape } from '@graphics-flow/types';

export interface UpdatedArtShapeWithShapeInfo {
  shapeIds: number[];
  artShape: CustomizeStockArtShape;
}
export class CustomizeStockArtHelper {
  static updateSelectedColorOverArtShape(artShape: CustomizeStockArtShape, currentColor: CustomColor, selectedColor: CustomColor): UpdatedArtShapeWithShapeInfo {
    const updatedCurveShapeInfo: UpdatedArtShapeWithShapeInfo = {
      artShape: null,
      shapeIds: [artShape.shapeId]
    };
    if (artShape.curveShapeColors?.length) {
      artShape.curveShapeColors?.map((curveShapeColor) => {
        if (curveShapeColor.color.hexValue?.toUpperCase() === currentColor?.hexValue.toUpperCase()) {
          curveShapeColor.color = { ...selectedColor, hexValue: selectedColor?.hexValue.toUpperCase() };
          updatedCurveShapeInfo.shapeIds.push(curveShapeColor.colorId);
        }
        return curveShapeColor;
      });
    }
    updatedCurveShapeInfo.artShape = artShape;
    return updatedCurveShapeInfo;
  }
}
