import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { CSAType, CustomColor, CustomizeStockArt, CustomizeStockArtCurveShapeColor, CustomizeStockArtDefaultColor, CustomizeStockArtDetailSection, CustomizeStockArtSections, SystemColor } from '@graphics-flow/types';
import { ArtHelper } from '../../helpers/art.helper';
import { CustomizeStockArtState, CustomizeStockArtStore } from './customize-stock-art.store';

@Injectable({ providedIn: 'root' })
export class CustomizeStockArtQuery {
  customizeStockArt$: Observable<CustomizeStockArt> = this.selectStateProps<CustomizeStockArt>((state) => state.customizeStockArt);
  activeSection$: Observable<string> = this.selectStateProps<string>((state) => state.ui.detail.activeSection);
  activeIndex$: Observable<number> = this.selectStateProps<number>((state) => state.ui.detail.activeIndex);
  colorInUse$: Observable<CustomizeStockArtCurveShapeColor[]> = this.selectStateProps<CustomizeStockArtCurveShapeColor[]>((state) => state.customizeStockArt.cdrShapes.colors);
  activeDetailSection$: Observable<string> = this.selectStateProps<string>((state) => state.ui.detail.detailActiveSection);
  loading$: Observable<boolean> = this.selectStateProps<boolean>((state) => state.loading);
  previewImage$: Observable<string> = this.selectStateProps<string>((state) => ArtHelper.buildCustomizeStockArtPngPreview(state.previewUrl));
  hasTextArt$: Observable<boolean> = this.selectStateProps<boolean>((state) => !!state.customizeStockArt.cdrShapes?.textShapes?.length);
  hasClipArt$: Observable<boolean> = this.selectStateProps<boolean>((state) => !!state.customizeStockArt.cdrShapes?.artShapes?.length);
  isDetailSubSectionActive$: Observable<boolean> = combineLatest([
    this.activeSection$,
    this.activeDetailSection$
  ]).pipe(
    map(([activeSection, activeDetailSection]) => (
      activeDetailSection !== CustomizeStockArtDetailSection.TEXT_DETAIL
      && activeDetailSection !== CustomizeStockArtDetailSection.CLIP_ART_DETAIL)
      || activeSection === CustomizeStockArtSections.SELECT_COLOR
      || activeSection === CustomizeStockArtSections.GLOBAL_COLOR
      || activeSection === CustomizeStockArtSections.REPLACE_CLIP_ART
      || activeSection === CustomizeStockArtSections.SAVE_DESIGN_TO_MY_ART_VIEW)
  );
  isPreviewUpdate$: Observable<boolean> = this.selectStateProps<boolean>((state) => state.isPreviewUpdate);
  isEditMode$: Observable<boolean> = this.activeSection$.pipe(map((activeSection: string) => activeSection !== CustomizeStockArtSections.SAVE_DESIGN_TO_MY_ART_VIEW));
  isViewChanged$: Observable<boolean> = combineLatest([
    this.activeSection$,
    this.activeDetailSection$
  ]).pipe(
    map(([activeSection, activeDetailSection]) => (!!activeSection || !!activeDetailSection))
  );
  activeCSAType$: Observable<CSAType> = this.selectStateProps<CSAType>((state) => state.CSAType);
  isNotReplaceFontOrClipArt$: Observable<boolean> = combineLatest([
    this.activeSection$,
    this.activeDetailSection$
  ]).pipe(
    map(([activeSection, activeDetailSection]) =>
      activeSection !== CustomizeStockArtDetailSection.REPLACE_CLIP_ART
      && activeDetailSection !== CustomizeStockArtDetailSection.EDIT_FONT
      && activeDetailSection !== CustomizeStockArtDetailSection.REPLACE_CLIP_ART
  ));

  canSaveArt$: Observable<boolean> = this.selectStateProps<boolean>((art) => {
    const hasAnyShapes = [
      ...art.customizeStockArt.cdrShapes.artShapes,
      ...art.customizeStockArt.cdrShapes.textShapes
    ].some((shape) => !shape.hide);

    return hasAnyShapes;
  });

  constructor(
    protected readonly store: CustomizeStockArtStore
  ) {
  }

  selectStateProps<T>(predicate): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): CustomizeStockArtState {
    return this.store.getValue();
  }

  getLoading(): boolean {
    return this.getValue().loading;
  }

  getGlobalColorByIndex(index: number): Observable<CustomColor> {
    return this.colorInUse$.pipe(
      map((colors: CustomizeStockArtCurveShapeColor[]) => colors[index].color)
    );
  }

  getActiveCSAType(): CSAType {
    return this.getValue().CSAType;
  }

  isShapeInfoExist(): boolean {
    return !!this.getValue().shapeIds?.length && !!this.getValue().shapeProperty;
  }
}
