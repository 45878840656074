import { Injectable } from '@angular/core';
import { cloneDeep as _cloneDeep, flattenDeep as _flattenDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { FolderHttpService } from '@graphics-flow/api';
import { Art, ArtFolderPackage, Folder, ID, RangedItemSelectionInfo, SelectedItemInfo } from '@graphics-flow/types';

import { ArtService } from '../art/art.service';
import { BillingPlansService } from '../billing-plans/billing-plans.service';
import { FolderService } from '../folder/folder.service';
import { MyArtSearchQuery } from './../my-art-search/my-art-search.query';
import { MyArtBulkSelectionQuery } from './my-art-bulk-selection.query';
import { MyArt, MyArtBulkSelectionStore } from './my-art-bulk-selection.store';

export const initialRangedItemSelectionInfo: RangedItemSelectionInfo = {
  rangeStart: null,
  rangeEnd: null
};

@Injectable({
  providedIn: 'root'
})
export class MyArtBulkSelectionService {
  selectedAllArtsAndFolder$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  keyStrokeSubscription: Subscription;
  isAllSelected$: Observable<boolean> = combineLatest([this.myArtBulkSelectionQuery.childrenCount$, this.myArtBulkSelectionQuery.selectedCount$, this.selectedAllArtsAndFolder$]).pipe(
    map(([childrenCount, selectedCount, selectedAllArtsAndFolder]: [number, number, number]) => {
      return childrenCount === selectedCount || selectedAllArtsAndFolder === selectedCount;
    })
  );
  activeArtIdsInDirectory$: BehaviorSubject<ID[]> = new BehaviorSubject<ID[]>(null);

  _rangedItemSelectionInfo: BehaviorSubject<RangedItemSelectionInfo> = new BehaviorSubject(_cloneDeep(initialRangedItemSelectionInfo));
  rangedItemSelectionInfo$: Observable<RangedItemSelectionInfo> = this._rangedItemSelectionInfo.asObservable();
  selectedItemIdsInRange: MyArt;
  rangedItemSelectionInfoSubscription: Subscription;

  constructor(
    private readonly artService: ArtService,
    private billingPlanService: BillingPlansService,
    private readonly folderHttpService: FolderHttpService,
    private readonly folderService: FolderService,
    private readonly myArtBulkSelectionStore: MyArtBulkSelectionStore,
    private readonly myArtSearchQuery: MyArtSearchQuery,
    private readonly myArtBulkSelectionQuery: MyArtBulkSelectionQuery
  ) { }

  selectFolder(id: ID) {
    this.myArtBulkSelectionStore.addFolder(id);
  }

  selectArt(id: ID) {
    this.myArtBulkSelectionStore.addArt(id);
  }

  selectAll() {
    this.myArtSearchQuery.activeArtsAndFolders$.pipe(
      take(1),
      tap(([folders, arts]: [Folder[], Art[]]) => {
        this.selectAllArtsAndFolders(arts.map(a => a.artId), folders.map(f => f.folderId));
      })
    ).subscribe();
  }

  removeFolder(id: ID) {
    this.myArtBulkSelectionStore.removeFolder(id);
  }

  removeArt(id: ID) {
    this.myArtBulkSelectionStore.removeArt(id);
  }

  reset() {
    this.myArtBulkSelectionStore.reset();
    this.setRangedItemSelectionInfo(null);
    this.selectedItemIdsInRange = null;
  }

  deleteArtsAndFolders(artIds: ID[], folderIds: ID[]): Observable<ArtFolderPackage> {
    return this.folderHttpService.deleteArtAndFolders(artIds, folderIds).pipe(
      tap(() => {
        this.artService.deleteArtEntities(artIds);
        this.folderService.deleteFolderEntities(folderIds);
        this.billingPlanService.updateResourceUsageInBackground();
      })
    );
  }

  removeFoldersAndArts(folderIds: ID[], artIds: ID[]): void {
    this.myArtBulkSelectionStore.removeFoldersAndArts(folderIds, artIds);
  }

  selectAllArtsAndFolders(artIds: ID[], folderIds: ID[]): void {
    this.myArtBulkSelectionStore.selectAll(artIds, folderIds);
  }

  getAllArtIds(): Observable<ID[]> {
    return this.folderHttpService.getAllArtIds(this.myArtSearchQuery.getActiveQueryParams());
  }

  initializeRangedItemSelectionSubscription(): void {
    if (!this.rangedItemSelectionInfoSubscription || this.rangedItemSelectionInfoSubscription?.closed) {
      this.rangedItemSelectionInfoSubscription = this.rangedItemSelectionInfo$.pipe(
        filter((rangedItemSelectionInfo: RangedItemSelectionInfo) => !!rangedItemSelectionInfo.rangeStart && !!rangedItemSelectionInfo.rangeEnd),
        withLatestFrom(this.myArtSearchQuery.activeArtsAndFolders$),
        tap(([rangedItemSelectionInfo, activeArtsAndFolders]: [ RangedItemSelectionInfo, [Folder[] , Art[]] ] ) => {
          const flattenActiveArtsAndFolders: (Folder | Art)[] = _flattenDeep(<any>activeArtsAndFolders);
          this.selectItemsInRange(rangedItemSelectionInfo, flattenActiveArtsAndFolders);
        })
      ).subscribe();
    }
  }

  setRangedItemSelectionInfo(selectedItemInfo: SelectedItemInfo): void {
    // when we need to reset the rangedItemSelectionInfo, just pass it with "null".
    if (!selectedItemInfo) {
      this._rangedItemSelectionInfo.next(_cloneDeep(initialRangedItemSelectionInfo));
      return;
    }

    const rangedItemSelectionInfo: RangedItemSelectionInfo = this._rangedItemSelectionInfo.getValue();

    if (selectedItemInfo.isShiftKeyActive && rangedItemSelectionInfo.rangeStart !== null) {
      rangedItemSelectionInfo.rangeEnd = selectedItemInfo;
    } else {
      rangedItemSelectionInfo.rangeStart = selectedItemInfo;
      rangedItemSelectionInfo.rangeEnd = null;
      // In between of ranged selection action, if we opt to pick new rangeStart we need to mark the existing items as selected.
      // then we need to start with the new rangeStart.
      this.selectedItemIdsInRange = {
        artIds: _cloneDeep(this.myArtBulkSelectionQuery.getValue().artIds),
        folderIds: _cloneDeep(this.myArtBulkSelectionQuery.getValue().folderIds)
      };
    }
    this._rangedItemSelectionInfo.next(rangedItemSelectionInfo);
  }

  selectItemsInRange(rangedItemSelectionInfo: RangedItemSelectionInfo, activeArtsAndFolders: (Folder | Art)[]): void {
    const rangeStartItemIndex: number = this.getSelectedItemIndex(activeArtsAndFolders, rangedItemSelectionInfo.rangeStart);
    const rangeEndItemIndex: number = this.getSelectedItemIndex(activeArtsAndFolders, rangedItemSelectionInfo.rangeEnd);

    const {artIds, folderIds}: MyArt = this.getItemIdsForSelectionInGivenRange(rangeStartItemIndex, rangeEndItemIndex, activeArtsAndFolders);

    this.myArtBulkSelectionStore.selectArtsAndFoldersByRange(
      [...artIds, ...this.selectedItemIdsInRange.artIds],
      [...folderIds, ...this.selectedItemIdsInRange.folderIds]
    );
  }

  getSelectedItemIndex(activeArtsAndFolders, selectedItemInfo: SelectedItemInfo): number {
    return activeArtsAndFolders.findIndex((activeArtOrFolder) => {
      if (activeArtOrFolder?.artId) {
        return activeArtOrFolder['artId'] === selectedItemInfo.id;
      }
      return activeArtOrFolder?.folderId === selectedItemInfo.id;
    });
  }

  getItemIdsForSelectionInGivenRange(startIndex: number, endIndex: number, activeArtsAndFolders: (Folder | Art)[]): MyArt {
    const [minIndex, maxIndex] = startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
    const splicedSelectedItems: (Folder | Art)[] = _cloneDeep(activeArtsAndFolders);

    splicedSelectedItems.splice(maxIndex + 1);

    return splicedSelectedItems.reduce((selectedItemIdsInRange: MyArt, selectedItem: (Folder | Art), itemIndex: number) => {
      if (minIndex <= itemIndex && itemIndex <= maxIndex) {
        if ((<Art>selectedItem).artId) {
          selectedItemIdsInRange.artIds.push((<Art>selectedItem).artId);
        } else {
          selectedItemIdsInRange.folderIds.push(selectedItem.folderId);
        }
      }
      return selectedItemIdsInRange;
    }, {artIds: [], folderIds: []});
  }

  selectAllResults(): void {
    if (this.activeArtIdsInDirectory$.getValue()) {
      this.updateSelectAllActiveIds(this.activeArtIdsInDirectory$.getValue());
      return;
    }
    this.getAllArtIds().pipe(
      tap((artIds: ID[]) => {
        this.activeArtIdsInDirectory$.next(artIds);
        this.updateSelectAllActiveIds(artIds);
      })
    ).subscribe();
  }

  updateSelectAllActiveIds(artIds: ID[]): void {
    this.selectedAllArtsAndFolder$.next(artIds.length + this.myArtSearchQuery.getLoadedFolderIds().length);
    this.selectAllArtsAndFolders(artIds, this.myArtSearchQuery.getLoadedFolderIds());
  }

  resetActiveArtIdsInDirectory(): void {
    this.activeArtIdsInDirectory$.next(null);
  }

  stopRangedItemSelectionSubscription(): void {
    if (this.rangedItemSelectionInfoSubscription && !this.rangedItemSelectionInfoSubscription?.closed) {
      this.rangedItemSelectionInfoSubscription?.unsubscribe();
    }
  }
}
