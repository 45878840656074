<div [id]="color.name"
  class="swatch"
  [class.disabled]="(customizeStockArtService.isArtUploading$ | async)"
  [class.active]="isSelectable && (selectedColor | isPaletteColorEqualsSelectedColor: color)"
  [style.backgroundColor]="color | colorFormatter: ColorType.HEX : ColorFormatOption.FULL"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="onTap()"></div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="show"
  cdkConnectedOverlayViewportMargin="8"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -4
    }
  ]"
>
  <gf-color-info-tooltip [color]="color"></gf-color-info-tooltip>
</ng-template>
