import { ID } from '../types/ID';

export enum ItemType {
  ART = 'ART',
  FOLDER = 'FOLDER'
}

export interface SelectedItemInfo {
  id: ID;
  itemType: ItemType;
  isShiftKeyActive?: boolean;
  isCtrlOrCommandKeyActive?: boolean;
}

export interface RangedItemSelectionInfo {
  rangeStart: SelectedItemInfo;
  rangeEnd: SelectedItemInfo;
}
