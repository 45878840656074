import { Injectable } from '@angular/core';
import { ID } from '@graphics-flow/types';
import { createState, setProp, Store, withProps } from '@ngneat/elf';
import { uniq as _uniq } from 'lodash-es';
import { GlobalHelpers } from '../../helpers/global.helpers';

export interface MyArt {
  artIds: ID[];
  folderIds: ID[];
}

export const initial: MyArt = {
  artIds: [],
  folderIds: []
};

const { state, config } = createState(
  withProps<MyArt>(initial)
);

@Injectable({
  providedIn: 'root'
})
export class MyArtBulkSelectionStore extends Store {
  constructor() {
    super({ name: 'my-art-bulk-selection', state, config });
  }

  addArt(artId: ID) {
    this.update(setProp('artIds', (artIds: ID[]) => _uniq([...artIds, artId])));
  }

  addFolder(folderId: ID) {
    this.update(setProp('folderIds', (folderIds: ID[]) => _uniq([...folderIds, folderId])));
  }

  selectAll(artIds: ID[], folderIds: ID[]) {
    this.update((state) => {
      return {
        artIds: _uniq([...state.artIds, ...artIds]),
        folderIds: _uniq([...state.folderIds, ...folderIds])
      };
    });
  }

  removeArt(artId: ID) {
    this.update(setProp('artIds', (artIds: ID[]) => artIds.filter((id: ID) => id !== artId)));
  }

  removeFolder(folderId: ID) {
    this.update(setProp('folderIds', (folderIds: ID[]) => folderIds.filter((id: ID) => id !== folderId)));
  }

  removeFoldersAndArts(folderIds: ID[], artIds: ID[]) {
    this.update((state) => ({
      artIds: GlobalHelpers.arrayRemove(state.artIds, artIds),
      folderIds: GlobalHelpers.arrayRemove(state.folderIds, folderIds)
    }));
  }

  selectArtsAndFoldersByRange(artIds: ID[], folderIds: ID[]) {
    this.update((state) => {
      return {
        ...state,
        artIds: _uniq([...artIds]),
        folderIds: _uniq([...folderIds]),
      };
    });
  }
}
