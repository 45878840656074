import { Injectable } from '@angular/core';
import { setProps } from '@ngneat/elf';
import { stateHistory } from '@ngneat/elf-state-history';
import { StateHistory } from '@ngneat/elf-state-history/src/lib/state-history';
import { FormGroup, ControlsOf } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, Subscription, throwError, timer } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, tap, mergeMap, takeUntil, catchError, finalize } from 'rxjs/operators';
import { cloneDeep as _cloneDeep, omit as _omit } from 'lodash-es';

import { CustomizeStockArtHttpService } from '@graphics-flow/api';
import {
  ApiError,
  Art,
  ArtTransformationUpdate,
  ChangeColorData,
  CSAType,
  CustomColor,
  CustomizeStockArt,
  CustomizeStockArtBoundingBoxData,
  CustomizeStockArtDetailSection,
  CustomizeStockArtPosition,
  CustomizeStockArtSections,
  CustomizeStockArtShape,
  CustomizeStockArtShapes,
  CustomizeStockArtTextShape,
  CustomizeStockArtTransformActions,
  CustomizeStockArtTransformation,
  CustomizeStockArtUndoRedoChange,
  CustomizeStockArtUndoRedoData,
  CustomizeStockArtUndoRedoType,
  ID,
  NudgePosition,
  ReplaceColorCorelData,
  SaveDesignCorelData,
  ShapeProperty,
  StockArt,
  User
} from '@graphics-flow/types';
import { LoadingService } from 'shared/ui';

import { ArtHelper } from '../../helpers/art.helper';
import { CustomizeStockArtQuery } from './customize-stock-art.query';
import { CustomizeStockArtStore } from './customize-stock-art.store';
import { CSAQuery, CSAService, CSAState, CSAStore } from './csa.state';
import { UserQuery } from './../user/user.query';
import { CSA_ERRORS_ALLOWS_EDITING, corelSessionExpiredCode } from './../../constants/errors.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomizeStockArtService {
  private customizeStockArtHistory: StateHistory<CSAStore, CSAState>;
  hasUndo$: Observable<boolean>;
  hasRedo$: Observable<boolean>;
  scrollOffset = 0;
  changeCount = 0;
  currentGlobalColor$: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedFontData$: BehaviorSubject<StockArt> = new BehaviorSubject(null);
  updateForm$: BehaviorSubject<CustomizeStockArt> = new BehaviorSubject(null);
  updateFormSubscription: Subscription;
  changeGlobalColor$: BehaviorSubject<ChangeColorData> = new BehaviorSubject(null);
  changeGlobalSubscription: Subscription;
  undoRedoSubscription: Subscription;
  undoRedoChange$: BehaviorSubject<CustomizeStockArtUndoRedoChange> = new BehaviorSubject(null);
  activeShapeId$: BehaviorSubject<number> = new BehaviorSubject(null);
  activeShapeIdSubscription: Subscription;
  timerToRefreshSession$: Observable<number>;
  destroyTimer$ = new Subject();
  scrollOffset$: BehaviorSubject<number> = new BehaviorSubject(0);
  showScrollToTopButton$: Observable<boolean> = this.scrollOffset$.pipe(
    debounceTime(200),
    map((offset: number) => offset > 20)
  );
  distressHistory = {};
  isArtUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly customizeStockArtStore: CustomizeStockArtStore,
    private readonly customizeStockArtQuery: CustomizeStockArtQuery,
    private readonly loadingService: LoadingService,
    private readonly customizeStockArtHttpService: CustomizeStockArtHttpService,
    private readonly userQuery: UserQuery,
    public readonly translateService: TranslateService,
    private readonly csaStore: CSAStore,
    private readonly csaQuery: CSAQuery,
    private readonly csaService: CSAService
  ) {
    this.csaQuery.csa$.pipe(
      filter((csa) => !!csa),
      tap((csa) => this.customizeStockArtStore.updateCustomizeStockArt(csa))
    ).subscribe();
  }

  initializeSession(art: Art, stockArtId: ID, csaType: CSAType): Observable<CustomizeStockArt> {
    const loaderId = 'initializeSession';
    const cdrFileName: string = ArtHelper.getCDRExtensionName(art, this.customizeStockArtQuery.getActiveCSAType() === CSAType.STOCK_ART);
    this.loadingService.showLoader(loaderId);

    return this.customizeStockArtHttpService.createCorelSession(art.assetId, stockArtId, cdrFileName, csaType).pipe(
      tap(() => {
        this.setupTimer();
      }),
      finalize(() => {
        this.loadingService.hideLoader(loaderId);
      }),
      map((customizeStockArt: CustomizeStockArt) => {
        this.customizeStockArtStore.updateCustomizeStockArt(customizeStockArt);
        this.csaService.updateCSA(customizeStockArt);
        this.customizeStockArtStore.setPreviewUrl(customizeStockArt.pngPreview);
        this.createHistory();
        return customizeStockArt;
      })
    );
  }

  createHistory(): void {
    this.customizeStockArtHistory = stateHistory(this.csaStore, {
      maxAge: 1000,
      resetFutureOnNewState: true
      // With the help of "resetFutureOnNewState" we can achive as below mentioned
      // As per CoralDRAW, When any changes happened in between the states! like...
      // Consider user performed 3 changes (3 undo's (A, B, C) & 0 redo's),
      // then reverted the last 2 changes (1 undo (A) & 2 redo's (B, C)), Now, trying to update new change(D),
      // In this case the CorelDraw is removing the future states (here it's 2 redo's (B, C))
      // then we will have (2 undo's (A, D) & 0 redo's), like one from the past changes(A) & the new change(D)
      // So to match the CorelDraw service, we are reverting the future from state history on new state update.
      });
    this.hasUndo$ = this.customizeStockArtHistory.hasPast$;
    this.hasRedo$ = this.customizeStockArtHistory.hasFuture$;
    this.listenFormUpdate();
    this.updateBoundingBox();
  }

  listenFormUpdate(): void {
    // Update form changes
    this.updateFormSubscription = this.updateForm$.pipe(
      debounceTime(500),
      filter((art: CustomizeStockArt) => !!art),
      map((art: CustomizeStockArt) => {
        const cloneArt: CustomizeStockArt = _cloneDeep(art);
        if (this.customizeStockArtQuery.isShapeInfoExist()) {
          cloneArt.shapeIds = this.customizeStockArtStore.getValue().shapeIds;
          cloneArt.shapeProperty = this.customizeStockArtStore.getValue().shapeProperty;
          //TODO: need to uncomment when bounding box issue is fixed.
          // art.boundingBox = this.customizeStockArtStore.getValue().boundingBox;
        }
        this.omitLocallyGeneratedCurveShapeColorsOverArtShape(cloneArt, CustomizeStockArtShapes.ARTSHAPES);
        this.omitLocallyGeneratedCurveShapeColorsOverArtShape(cloneArt, CustomizeStockArtShapes.CUSTOMSHAPES);
        return cloneArt;
      }),
      filter(() => this.customizeStockArtQuery.isShapeInfoExist()),
      switchMap((art) => {
        this.customizeStockArtStore.updateCustomizeStockArt(art);
        this.csaService.updateCSA(art);
        const isPreviewUpdate: boolean = this.customizeStockArtStore.getValue().isPreviewUpdate;
        this.changeCount = isPreviewUpdate ? this.changeCount + 1 : 0;
        return this.saveAndUpdateCustomizeStockArt(art);
      }),
      tap((art: CustomizeStockArt) => {
        this.setActiveLayerIndexBasedShapeId(art);
        this.customizeStockArtStore.updateCustomizeStockArt(art);
        this.customizeStockArtHistory.pause();
        this.csaService.updateCSA(art);
        this.customizeStockArtHistory.resume();
      }),
      catchError((error: ApiError) => this.handleError(error))
    ).subscribe(() => {
      // Update timer - reset it to the start
      this.setupTimer();
    });
    this.undoRedoSubscription = this.undoRedoChange$.pipe(
      debounceTime(500),
      filter((undoRedo: CustomizeStockArtUndoRedoChange) => !!undoRedo?.operation),
      switchMap((undoRedo: CustomizeStockArtUndoRedoChange) => {
        this.customizeStockArtStore.setLoading(true);
        const customizeStockArt = this.getCustomizeStockArt();
        const data: CustomizeStockArtUndoRedoData = {
          userId: customizeStockArt.userId,
          cdrFileName: customizeStockArt.cdrFileName,
          artType: this.customizeStockArtQuery.getActiveCSAType(),
          isUpdate: true,
          repeats: undoRedo.count
        };
        if (undoRedo.operation === CustomizeStockArtUndoRedoType.Undo) {
          this.customizeStockArtHistory.jump(undoRedo.count * -1);
          this.setActiveLayerIndexBasedShapeId(this.getCustomizeStockArt());
          return this.customizeStockArtHttpService.undoCorelDrawDoc(data);
        } else {
          this.customizeStockArtHistory.jump(undoRedo.count);
          this.setActiveLayerIndexBasedShapeId(this.getCustomizeStockArt());
          return this.customizeStockArtHttpService.redoCorelDrawDoc(data);
        }
      }),
      tap((art: CustomizeStockArt) => {
        this.customizeStockArtStore.setPreviewUrl(art.pngPreview);
        this.customizeStockArtStore.setLoading(false);
        this.checkAndResetCurrentView(art);
      }),
      catchError((error: ApiError) => this.handleError(error))
    ).subscribe((art: CustomizeStockArt) => {
      this.customizeStockArtStore.updateCustomizeStockArt(art);
      this.customizeStockArtHistory.pause();
      this.csaService.updateCSA(art);
      this.customizeStockArtHistory.resume();
      // Update timer - reset it to the start
      this.setupTimer();
    });
  }

  checkAndResetCurrentView(customizeStock: CustomizeStockArt) {
    const shapeId = this.activeShapeId$.getValue();

    if (!shapeId) return;

    const arts = [
      ...customizeStock.cdrShapes.artShapes,
      ...customizeStock.cdrShapes.customShapes,
      ...customizeStock.cdrShapes.textShapes
    ];
    const artShape = arts.find((artShape) => artShape.shapeId === shapeId);

    if (artShape) return;

    this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.HOME);
  }

  undo(count: number = 1): void {
    const data: CustomizeStockArtUndoRedoChange = {
      operation: CustomizeStockArtUndoRedoType.Undo,
      count: count
    };
    this.undoRedoChange$.next(data);
  }

  redo(count: number = 1): void {
    const data: CustomizeStockArtUndoRedoChange = {
      operation: CustomizeStockArtUndoRedoType.Redo,
      count: count
    };
    this.undoRedoChange$.next(data);
  }

  resetAllCustomization(setSection: boolean = true): void {
    if (setSection) {
      this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.HOME);
    }
    this.clearSession(null);
  }

  isStateHistoryHasPast(): boolean {
    return this.customizeStockArtHistory?.hasPast ?? false;
  }

  // This is used to update API and store.
  saveAndUpdateCustomizeStockArt(art: CustomizeStockArt): Observable<CustomizeStockArt> {
    return this.updateCustomizeStockArt(art).pipe(
      take(1),
      map((art: CustomizeStockArt) => {
        this.setLayerPosition(null);
        this.customizeStockArtStore.setPreviewUrl(art.pngPreview);
        // Used to reset the recently updated shape Info...
        this.updateShapeInfo([], null);
        return art;
      })
    );
  }

  // This is used to update only API value to show preview.
  updateCustomizeStockArt(art: CustomizeStockArt): Observable<CustomizeStockArt> {
    this.customizeStockArtStore.setLoading(true);
    return this.customizeStockArtHttpService.updateCorelDrawDoc(art).pipe(
      tap(() => {
        this.customizeStockArtStore.setLoading(false);
      })
    );
  }

  resetPreview(): void {
    this.undo(this.changeCount);
    this.changeCount = 0;
    this.updateIsPreviewUpdate(false);
  }

  resetChangeCount(): void {
    this.changeCount = 0;
  }

  setActiveSection(section: CustomizeStockArtSections, index: number = -1, shapeId?: number): void {
    this.customizeStockArtStore.setActiveSection(section, index);
    if (shapeId) {
      this.activeShapeId$.next(shapeId);
    }
  }

  editTextDetails(index: number, shapeId: number): void {
    this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.EDIT_TEXT_DETAILS, index);
    this.customizeStockArtStore.setDetailActiveSection(CustomizeStockArtDetailSection.TEXT_DETAIL);
    this.activeShapeId$.next(shapeId);
  }

  selectColor(index: number, shapeId: number): void {
    this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.SELECT_COLOR, index);
    this.activeShapeId$.next(shapeId);
  }

  editGlobalColor(index: number): void {
    this.customizeStockArtQuery.getGlobalColorByIndex(index).pipe(
      take(1),
      tap(() => {
        this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.GLOBAL_COLOR, index);
        this.listenGlobalColorChange();
      })
    ).subscribe();
  }

  listenGlobalColorChange(): void {
    this.changeGlobalSubscription = this.changeGlobalColor$.pipe(
      debounceTime(500),
      filter((color: ChangeColorData) => !!color),
      mergeMap((color) => this.changeGlobalColor(color.color, color.shapeColorIds, color.outlineColorIds)),
      catchError((error: ApiError) => this.handleError(error))
    ).subscribe();
  }

  destroyChangeGlobalSubscription(): void {
    this.changeGlobalSubscription?.unsubscribe();
    this.changeGlobalColor$.next(null);
  }

  changeGlobalColor(color: CustomColor, shapeColorIds: number[], outlineColorIds: number[]): Observable<CustomizeStockArt> {
    this.customizeStockArtStore.setLoading(true);
    const art = this.getCustomizeStockArt();
    const data: ReplaceColorCorelData = {
      cdrFileName: art.cdrFileName,
      userId: art.userId,
      newColor: color,
      artType: this.customizeStockArtQuery.getActiveCSAType(),
      isUpdate: true,
      shapeColorIds,
      outlineColorIds
    };
    return this.customizeStockArtHttpService.replaceColorCorelDrawDoc(data).pipe(
      tap((art: CustomizeStockArt) => {
        this.changeCount = this.changeCount + 1;
        this.customizeStockArtStore.setPreviewUrl(art.pngPreview);
        this.customizeStockArtStore.updateCustomizeStockArt(art);
        this.csaService.updateCSA(art);
        this.customizeStockArtStore.setLoading(false);
      })
    );
  }

  setActiveTextDetailSection(section: CustomizeStockArtDetailSection): void {
    this.customizeStockArtStore.setDetailActiveSection(section);
  }

  moveBackFromSelectColor(): void {
    switch (this.customizeStockArtStore.getValue().ui.detail.activeSection) {
      case CustomizeStockArtSections.EDIT_TEXT_DETAILS:
        this.customizeStockArtStore.setDetailActiveSection(CustomizeStockArtDetailSection.TEXT_DETAIL);
        break;
      case CustomizeStockArtSections.EDIT_CLIP_ART:
        this.customizeStockArtStore.setDetailActiveSection(CustomizeStockArtDetailSection.CLIP_ART_DETAIL);
        break;
      default:
        this.destroyChangeGlobalSubscription();
        this.activeShapeId$.next(null);
        this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.HOME);
    }
  }

  getActiveIndex(): number {
    return this.customizeStockArtStore.getValue().ui.detail.activeIndex;
  }

  reset(): void {
    this.customizeStockArtStore.reset();
    this.csaStore.reset();
    this.customizeStockArtHistory?.clear();
  }

  clearSession(art: Art): void {
    this.scrollOffset$.next(0);
    this.updateIsPreviewUpdate(false);
    this.customizeStockArtHistory?.clear();
    this.resetChangeCount();
    this.updateFormSubscription?.unsubscribe();
    this.updateForm$.next(null);
    this.undoRedoSubscription?.unsubscribe();
    this.undoRedoChange$.next(null);
    this.destroyChangeGlobalSubscription();
    this.activeShapeIdSubscription?.unsubscribe();
    if (art) {
      this.distressHistory = {};
      this.customizeStockArtHttpService.clearCorelSession(this.getSessionFileName(art)).subscribe();
    }
  }

  getSessionFileName(art: Art): string {
    if (!art?.assetId) return '';
    return art.assetId.toString() + ArtHelper.getCDRExtensionName(art, this.customizeStockArtQuery.getActiveCSAType() === CSAType.STOCK_ART);
  }

  editClipArt(index: number, shapeId: number, activeSection: CustomizeStockArtSections): void {
    this.customizeStockArtStore.setActiveSection(activeSection, index);
    this.customizeStockArtStore.setDetailActiveSection(CustomizeStockArtDetailSection.CLIP_ART_DETAIL);
    this.activeShapeId$.next(shapeId);
  }

  updateIsPreviewUpdate(isPreviewUpdate: boolean): void {
    this.customizeStockArtStore.setIsPreviewUpdate(isPreviewUpdate);
  }

  navigateSaveDesignMyArtView(): void {
    this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.SAVE_DESIGN_TO_MY_ART_VIEW);
  }

  saveDesignToMyArt(designName: string, folderId: ID, art: Art, csaType: CSAType, isUpdate: boolean): Observable<Art> {
    const saveDesignData: SaveDesignCorelData = {
      assetId: art.assetId,
      cdrFileName: ArtHelper.getCDRExtensionName(art, csaType === CSAType.STOCK_ART),
      designName,
      folderId,
      artType: csaType,
      isUpdate,
      artId: art.artId
    };
    return this.customizeStockArtHttpService.saveDesignToMyArt(saveDesignData).pipe(
      tap(() => {
        this.updateSaveDesignInFolderID(folderId);
        this.customizeStockArtHistory.clear();
      }),
      catchError((err: ApiError) => {
        if (err.errorCode === corelSessionExpiredCode) {
          this.cancelTimer();
        }
        return throwError(err);
      })
    );
  }

  getSaveDesignInFolderID(): ID {
    return this.customizeStockArtStore.getValue()?.saveDesignInFolderID;
  }

  setActiveIndex(index: number): void {
    this.customizeStockArtStore.setActiveIndex(index);
  }

  setLayerPosition(layerPosition: string): void {
    this.customizeStockArtStore.setLayerPosition(layerPosition);
  }

  setCSAType(type: CSAType): void {
    this.customizeStockArtStore.update(setProps({ CSAType: type }));
  }

  updateSaveDesignInFolderID(folderId: ID): void {
    this.customizeStockArtStore.updateSaveDesignInFolderID(folderId);
  }

  updateShapeInfo(shapeIds: number[], shapeProperty: ShapeProperty, boundingBox: boolean = true): void {
    this.customizeStockArtStore.update(setProps({
      shapeIds,
      shapeProperty,
      boundingBox
    }));
  }

  updateShapesPositionAndTransformation(shapeValue: FormGroup<ControlsOf<CustomizeStockArtShape | CustomizeStockArtTextShape>>, event: ArtTransformationUpdate): void {
    if (event.type === CustomizeStockArtTransformActions.NUDGE) {
      shapeValue.controls.position.setValue(<CustomizeStockArtPosition>event.value);
      const shapePropertyBasedOnNudgeAction: ShapeProperty = (event?.direction === NudgePosition.ArrowUp || event?.direction === NudgePosition.ArrowDown) ? ShapeProperty.positionY : ShapeProperty.positionX;
      this.updateShapeInfo([shapeValue.value?.shapeId], shapePropertyBasedOnNudgeAction);
      return;
    }
    shapeValue.controls.transformation.setValue(<CustomizeStockArtTransformation>event.value);
    this.updateShapeInfo([shapeValue.value?.shapeId], ShapeProperty.flip);
  }

  replaceClipArt(shapeId: number, newClipArt: string): void {
    this.customizeStockArtStore.setLoading(true);
    const customizeStockArt = this.getCustomizeStockArt();
    this.customizeStockArtHttpService.replaceClipArtCorelDrawDoc(customizeStockArt.cdrFileName, shapeId, newClipArt).pipe(
      tap(art => {
        this.changeCount = this.changeCount + 1;
        this.customizeStockArtStore.setPreviewUrl(art.pngPreview);
        this.customizeStockArtStore.updateCustomizeStockArt(art);
        this.csaService.updateCSA(art);
        this.customizeStockArtStore.setLoading(false);
        // once we replace clipArt, the shapeId will be changed, to maintain the state we need to
        // set the shapeId based on the activeIndex from store!
        this.setActiveLayerIndexBasedShapeId(art, true);
      }),
      catchError((error: ApiError) => this.handleError(error))
    ).subscribe(() => {
      // Update timer -> reset it to the start
      this.setupTimer();
    });
  }

  omitLocallyGeneratedCurveShapeColorsOverArtShape(art: CustomizeStockArt, key: string): void {
    art.cdrShapes[key].forEach((shape: CustomizeStockArtShape) => {
      const curveShapeColors = shape.curveShapeColors;
      if (curveShapeColors?.length === 1 && !curveShapeColors[0].color) {
        shape.curveShapeColors = [];
      }
    });
  }

  getObjectSelectionPreview(shapeId: number, createBoundingBox: boolean = true): Observable<string> {
    this.customizeStockArtStore.setLoading(true);
    const customizeStockArt = this.getCustomizeStockArt();
    const data: CustomizeStockArtBoundingBoxData = {
      userId: customizeStockArt.userId,
      cdrFileName: customizeStockArt.cdrFileName,
      artType: this.customizeStockArtQuery.getActiveCSAType(),
      isUpdate: true,
      shapeId: shapeId,
      boundingBox: createBoundingBox
    };
    return this.customizeStockArtHttpService.getObjectSelectionPreview(data).pipe(
      tap((artPreview: string) => {
        this.customizeStockArtStore.setPreviewUrl(artPreview);
        this.customizeStockArtStore.setLoading(false);
      })
    );
  }

  updateBoundingBox(): void {
    //TODO : need to uncomment, once bounding box issue is fixed.
    // this.activeShapeIdSubscription = this.activeShapeId$.pipe(
    //   pairwise(),
    //   switchMap(([prevShapeId, activeShapeId]) => {
    //     if (activeShapeId || prevShapeId) {
    //       return this.getObjectSelectionPreview(activeShapeId ?? prevShapeId, activeShapeId !== null);
    //     }
    //     return of(null);
    //   })
    // ).subscribe(() => {}, (error: ApiError) => {
    //   this.handleError(error);
    // });
  }

  setActiveLayerIndexBasedShapeId(art: CustomizeStockArt, replaceClipArt: boolean = false): void {
    // Used to get the updated layer index, after updating the layer position.
    const { textShapes, artShapes, customShapes } = art.cdrShapes;
    const sections = {
      editTextDetails: textShapes,
      editClipArt: artShapes,
      editMyImage: customShapes
    };
    const activeLayer = this.customizeStockArtStore.getValue()?.ui?.detail;
    const activeShapes = sections[activeLayer.activeSection];

    if (!activeShapes) return;

    const activeIndex = activeShapes.findIndex((shape: CustomizeStockArtShape) => shape.shapeId === this.activeShapeId$.getValue());
    // If we replace clipart! as we know that the shapeId will change, so maintain it, based on the current activeIndex
    // we will find the shapeId and set it as activeShapeId. to get rid of data mismatch in UI.
    if (replaceClipArt || activeIndex < 0) {
      const activeShapeId = activeShapes[activeLayer.activeIndex]?.shapeId;
      this.activeShapeId$.next(activeShapeId);
      if (activeLayer.activeSection === CustomizeStockArtSections.EDIT_MY_IMAGE) {
        this.customizeStockArtStore.setActiveSection(CustomizeStockArtSections.HOME);
      }
    }
    if (activeIndex >= 0) {
      this.setActiveIndex(activeIndex);
    }
  }

  setupTimer() {
    if (this.timerToRefreshSession$) {
      this.destroyTimer$.next(null);
    }

    const ONE_MIN = 60000;
    this.timerToRefreshSession$ = timer(ONE_MIN, ONE_MIN);

    this.timerToRefreshSession$.pipe(
      takeUntil(this.destroyTimer$)
    ).subscribe(() => {
      const user: User = this.userQuery.getUser();
      const stockArt: CustomizeStockArt = this.getCustomizeStockArt();

      if (!stockArt) {
        console.error(`refresh session stockArt not found`);
        return;
      }
      // Error code handled in common graphics flow service
      this.customizeStockArtHttpService.keepCorelSessionAlive(stockArt.cdrFileName, user.userId).pipe(
        catchError((error: ApiError) => this.handleError(error))
        ).subscribe();
    });
  }

  cancelTimer() {
    if (this.timerToRefreshSession$) {
      this.destroyTimer$.next(null);
    }
  }

  handleError(error: ApiError) {
    if (error.errorCode === corelSessionExpiredCode) {
      this.cancelTimer();
    }
    if (CSA_ERRORS_ALLOWS_EDITING.includes(error.errorCode)) {
      this.customizeStockArtStore.setLoading(false);
    }
    return throwError(() => error);
  }

  setScrollOffset(event: Event): void {
    const elementTarget = event.currentTarget as HTMLDivElement;
    this.scrollOffset$.next(elementTarget.scrollTop);
  }

  resetScrollToTop(): void {
    document.querySelector('.csa-scroll-container')?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getCustomizeStockArt(): CustomizeStockArt {
    return this.customizeStockArtQuery.getValue()?.customizeStockArt;
  }

  uploadArtToCSA(file: File): void {
    this.customizeStockArtStore.setLoading(true);
    this.isArtUploading$.next(true);
    const cdrFileName = this.getCustomizeStockArt()?.cdrFileName;
    this.customizeStockArtHttpService.uploadArtToCSA(file, cdrFileName).pipe(
      tap((art: CustomizeStockArt) => {
        this.updateCustomizeStockArtAndPreviewUrl(art);
        this.customizeStockArtStore.setLoading(false);
      }),
      catchError((error: ApiError) => this.handleError(error)),
      finalize(() => this.isArtUploading$.next(false))
    ).subscribe();
  }

  addMyArtToCSA(myArtId: ID): void {
    this.customizeStockArtStore.setLoading(true);
    this.isArtUploading$.next(true);
    const customizeStockArt = _cloneDeep(this.getCustomizeStockArt());
    this.customizeStockArtHttpService.addUploadedArtToCSA(myArtId, customizeStockArt).pipe(
      tap((art: CustomizeStockArt) => {
        this.updateCustomizeStockArtAndPreviewUrl(art);
        this.customizeStockArtStore.setLoading(false);
      }),
      catchError((error: ApiError) => this.handleError(error)),
      finalize(() => this.isArtUploading$.next(false))
    ).subscribe();
  }

  updateCustomizeStockArtAndPreviewUrl(art: CustomizeStockArt): void {
    this.customizeStockArtStore.updateCustomizeStockArt(art);
    this.csaService.updateCSA(art);
    this.customizeStockArtStore.setPreviewUrl(art.pngPreview);
  }

  updateCSA() {
    const clonedArt: CustomizeStockArt = _cloneDeep(this.getCustomizeStockArt());
    const customizeStockArt = _omit(clonedArt, 'successful');
    this.updateForm$.next(customizeStockArt);
  }
}
