import { Pipe, PipeTransform } from '@angular/core';
import { unionWith as _unionWith, isEqual as _isEqual } from 'lodash-es';
import { CustomColor, CustomizeStockArtCurveShapeColor } from '@graphics-flow/types';

@Pipe({
  name: 'unique'
})

export class UniquePipe implements PipeTransform {
  transform(colors: Array<CustomColor | CustomizeStockArtCurveShapeColor>, isCustomColor: boolean = false): Array<CustomColor | CustomizeStockArtCurveShapeColor> {
    return _unionWith(colors, (colorA, colorB) => {
      const colorObjA = isCustomColor ? colorA : (colorA as CustomizeStockArtCurveShapeColor).color;
      const colorObjB = isCustomColor ? colorB : (colorB as CustomizeStockArtCurveShapeColor).color;
      return this.isGivenColorsEqual(colorObjA, colorObjB);
    });
  }

  isGivenColorsEqual(colorA, colorB) {
    if (colorA?.isCMYK && colorB?.isCMYK) {
      return colorA?.name === colorB?.name && _isEqual(colorA?.cmyk, colorB?.cmyk);
    }
    return (!colorA?.isCMYK && !colorB?.isCMYK) && colorA?.name === colorB?.name && colorA?.hexValue === colorB?.hexValue;
  }

}
