import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { Translations } from '@graphics-flow/shared/assets';
import { ColorFormatOption, ColorType, CustomColor, SystemColor } from '@graphics-flow/types';
import { CustomizeStockArtService } from '@graphics-flow/util';
import { WindowService } from 'shared/util';

@UntilDestroy()
@Component({
  selector: 'gf-color-swatch',
  templateUrl: './color-swatch.component.html',
  styleUrls: ['./color-swatch.component.scss']
})
export class ColorSwatchComponent {
  show = false;
  ColorFormatOption: typeof ColorFormatOption = ColorFormatOption;
  ColorType: typeof ColorType = ColorType;

  @Input() color: SystemColor | CustomColor;
  @Input() isSystemColorPalette: boolean = false;
  @Input() isSelectable: boolean = false;
  @Input() selectedColor: SystemColor | CustomColor;
  @Output() changeColor: EventEmitter<SystemColor | CustomColor> = new EventEmitter<SystemColor | CustomColor>();

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.isTouchScreen) {
      this.show = true;
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onMouseLeave() {
    this.show = false;
  }

  get isTouchScreen() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    // Helper function to check if a media query matches
    const mq = (query: any) => window.matchMedia(query).matches;

    // Quick check: if 'ontouchstart' exists, it's definitely a touch device
    if ('ontouchstart' in window) {
        return true;
    }

    // Fallback: construct a complex media query to test for touch capability
    // The 'heartz' part ensures the query doesn't end prematurely
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

    // Return the result of our media query test
    return mq(query);
  }

  constructor(
    public translation: Translations,
    private windowService: WindowService,
    public readonly customizeStockArtService: CustomizeStockArtService
  ) {}

  onTap() {
    this.windowService.smallDown$.pipe(
      untilDestroyed(this),
      tap((smallDown) => {
        if (smallDown) {
          this.show = !this.show;
        }
      })
    ).subscribe();
    if (this.isSelectable) {
      let selectColor: CustomColor = this.color;
      if (this.isSystemColorPalette) {
        // TODO: Temporarily setting the CMYK values to zero
        selectColor = {
          name: this.color.name,
          hexValue: `#${this.color.hexValue}`,
          rgb: this.color.rgb,
          isCMYK: false,
          cmyk: {
            cyan: 0,
            magenta: 0,
            yellow: 0,
            black: 0
          }
        };
      }
      this.changeColor.emit(selectColor);
    }
  }
}
