import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { flattenDeep as _flattenDeep } from 'lodash-es';

import { EventHelpers } from 'shared/util';
import { Art, ArtFolderPackage, GenericRemovalDialogData, GenericWarningDialogData, ID, ItemType, NotificationType, TableFile, WebJob, WebJobStatus } from '@graphics-flow/types';
import {
  ArtService,
  FolderService,
  MyArtBulkSelectionQuery,
  GraphicsFlowService,
  MyArtBulkSelectionService,
  MyArtSearchQuery,
  MyArtSearchService,
  BillingPlansService,
  TagService
} from '@graphics-flow/util';

import { GenericDataRemovalDailogComponent, GenericWarningDialogComponent, LoadingService, NotificationService } from 'shared/ui';
import { Translations } from '@graphics-flow/shared/assets';
import { Observable, throwError, Subscription, interval } from 'rxjs';
import { ArtHttpService } from '@graphics-flow/api';
import { LinkNotificationMessageComponent } from '../components/link-notification-message/link-notification-message.component';

@Injectable({
  providedIn: 'root'
})
export class MyArtBulkActionsService {
  webJobStatusSubscription: Subscription;

  constructor(
    private readonly artService: ArtService,
    private readonly folderService: FolderService,
    private myArtBulkSelectionQuery: MyArtBulkSelectionQuery,
    private myArtBulkSelectionService: MyArtBulkSelectionService,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly translations: Translations,
    private notificationService: NotificationService,
    private readonly myArtSearchQuery: MyArtSearchQuery,
    private readonly myArtSearchService: MyArtSearchService,
    private readonly graphicsFlowService: GraphicsFlowService,
    private readonly billingPlansService: BillingPlansService,
    private readonly tagService: TagService,
    private readonly artHttpService: ArtHttpService,
    private readonly loadingService: LoadingService
  ) {
  }

  selectAllChange(checkboxChange: MatCheckboxChange, checkBox: MatCheckbox) {

    if (checkboxChange.source.indeterminate) {
      // in intermediate state click - make it unselect all
      this.myArtBulkSelectionService.reset();
      checkBox.checked  = false;
      return;
    }

    if (checkboxChange.checked) {
      this.myArtBulkSelectionService.selectAll();
    } else {
      this.myArtBulkSelectionService.reset();
    }
  }

  selectChange(isShiftPressed: boolean, item: TableFile | Art): void {
    const selectedItems = _flattenDeep(this.myArtBulkSelectionQuery.getSelectedArtAndFolderIds());
    this.myArtBulkSelectionService.setRangedItemSelectionInfo({
      id: item.artId ?? item.folderId,
      itemType: item.artId ? ItemType.ART : ItemType.FOLDER,
      isShiftKeyActive: isShiftPressed
    });
    if (!item.artId) {
      this.folderListSelection(selectedItems.includes(item.folderId), item.folderId, isShiftPressed);
    } else {
      this.artListSelection(selectedItems.includes(item.artId), item.artId, isShiftPressed);
    }
  }

  folderListSelection(isSelected: boolean, folderId: ID, isShiftPressed: boolean) {
    if (!isSelected || isShiftPressed) {
      this.myArtBulkSelectionService.selectFolder(folderId);
    } else {
      this.myArtBulkSelectionService.removeFolder(folderId);
    }
  }

  artListSelection(isSelected: boolean, artId: ID, isShiftPressed: boolean) {
    if (!isSelected || isShiftPressed) {
      this.myArtBulkSelectionService.selectArt(artId);
    } else {
      this.myArtBulkSelectionService.removeArt(artId);
    }
  }

  stopAndPrevent(event: any) {
    EventHelpers.stopAndPrevent(event);
  }

  bulkDelete() {
    const [artIds, folderIds]: [ID[], ID[]] = this.myArtBulkSelectionQuery.getSelectedArtAndFolderIds();
    this.dialog.open(GenericDataRemovalDailogComponent, {
      data: <GenericRemovalDialogData>{
        header: this.translations.common.bulk_delete_header,
        body: this.translateService.instant(this.translations.common.bulk_art_delete),
        removalData: '',
        note: this.myArtSearchService.getDeleteModalNote(),
        continueText: this.translations.common.delete_files,
        cancelText: this.translations.common.cancel
      },
      autoFocus: false,
      closeOnNavigation: true,
      panelClass: 'mobile-screen-modal-medium-y'
    }).afterClosed().pipe(
      filter(confirmed => !!confirmed),
      switchMap(() => {
        this.updateInProgressStatus(artIds, folderIds, true);
        // Remove all items from bulk action selection.
        this.myArtBulkSelectionService.reset();
        // When user does a bulk action delete, by default we need to set true for overAllLoader.
        // API - with Success - Will proceed to retrive the next set of arts.
        // API - with Failure - Will set overAllLoader as false.
        this.myArtSearchService.setLoaderState(true);
        this.graphicsFlowService.isBulkActionInProgress$.next(true);
        return this.deleteSelectedArtsAndFolders(artIds, folderIds);
      }),
      switchMap(() => this.tagService.getAllTags(true))
    ).subscribe(() => {
      // When user does bulk delete! we need to update the list with recent info.
      // so it's better we can refresh the list.
      this.myArtSearchService.refreshArtSection();
      this.notificationService.showNotification(NotificationType.SUCCESS, this.translateService.instant(this.translations.common.deleted_successfully),
      this.translateService.instant(this.translations.common.x_files_have_been_moved_to_the_deleted_files_section, {
        x: (folderIds.length + artIds.length) > 1 ? (folderIds.length + artIds.length + ' files') : (folderIds.length + artIds.length + ' file')
      }));
      this.graphicsFlowService.isBulkActionInProgress$.next(false);
    });
  }

  updateInProgressStatus(arts: ID[], folders: ID[], status: boolean): void {
    if (this.myArtSearchQuery.getSearchValue()) {
      return;
    }
    if (arts?.length) {
      this.artService.updateArtInProgress(arts, status);
    }
    if (folders?.length) {
      this.folderService.updateFolderInProgress(folders, status);
    }
  }

  private getBodyTextForDeleteConfirmation(): string {
    const [artIds, folderIds]: [ID[], ID[]] = this.myArtBulkSelectionQuery.getSelectedArtAndFolderIds();
    const totalNumItems: number = artIds?.length + folderIds?.length;
    if (totalNumItems > 1) {
      return this.translateService.instant(this.translations.art.my_art_bulk_delete_file_confirmation_message);
    } else {
      if (artIds?.length) {
        return this.translateService.instant(this.translations.art.my_art_delete_file_confirmation_message, {
          x: 'file'
        });
      } else {
        return this.translateService.instant(this.translations.art.my_art_delete_file_confirmation_message, {
          x: 'folder'
        });
      }
    }
  }

  deleteSelectedArtsAndFolders(artIds: ID[], folderIds: ID[]): Observable<ArtFolderPackage>  {
    return this.myArtBulkSelectionService.deleteArtsAndFolders(artIds, folderIds).pipe(
      catchError((err) => {
        this.myArtSearchService.setLoaderState();
        this.updateInProgressStatus(artIds, folderIds, false);
        this.graphicsFlowService.isBulkActionInProgress$.next(false);
        this.notificationService.showNotification(NotificationType.ERROR, err.statusText, err.message);
        return throwError(err);
      })
      );
  }

  openPermanentlyDeleteAllModal(): void {
    this.dialog.open(GenericWarningDialogComponent, {
      data : <GenericWarningDialogData> {
        icon: 'info_outline',
        header: this.translations.permanently_delete.all,
        body: this.translations.permanently_delete.confirmation_msg_all,
        cancelText: this.translations.common.cancel,
        continueText: this.translations.permanently_delete.all
      },
      autoFocus: false,
      panelClass: 'mobile-screen-modal'
    }).afterClosed().pipe(
      filter((confirmed) => confirmed),
      tap(() => {
        this.myArtSearchService.setMyArtHeaderHeight();
        this.graphicsFlowService.isDeleteJobInProgress = true;
      }),
      switchMap(() => this.artService.deleteAllArtsAndFolders()),
      tap(() => this.startCheckingWebJobStatus()),
      catchError((err) => {
        this.graphicsFlowService.isDeleteJobInProgress = false;
        return throwError(err);
      })
    ).subscribe();
  }

  startCheckingWebJobStatus(): void {
    // Used to clear the existing subscription before creating a new subscription.
    this.stopCheckingWebJobStatus();

    this.webJobStatusSubscription = interval(5000).pipe(
      switchMap(() => this.artService.getJobStatus()),
      tap((webJob: WebJob) => {
        this.graphicsFlowService.isDeleteJobInProgress = webJob?.active || false;
        if (!webJob?.active) {
          this.myArtSearchService.refreshArtSection();
          if (webJob?.jobStatus === WebJobStatus.SUCCESS) {
            this.artService.removeTrashedArts();
            this.folderService.resetBreadcrums();
            this.notificationService.showNotification(NotificationType.SUCCESS, this.translateService.instant(this.translations.art.files_deleted_successfully), '');
            this.billingPlansService.getOrganizationResourceUsage().subscribe();
          }
          this.stopCheckingWebJobStatus();
        }
      }),
      catchError((err) => {
        this.graphicsFlowService.isDeleteJobInProgress = false;
        return throwError(err);
      })
    ).subscribe();
  }

  stopCheckingWebJobStatus(): void {
    if (this.webJobStatusSubscription && !this.webJobStatusSubscription.closed) {
      this.webJobStatusSubscription.unsubscribe();
      this.webJobStatusSubscription = null;
    }
  }

  addRemoveFromArtPortal(artIdList: ID[], show: boolean, linkMsgComponent: LinkNotificationMessageComponent): void {
    if (!artIdList.length) {
      this.myArtBulkSelectionService.reset();
      this.notificationService.showNotification(
        NotificationType.ERROR,
        this.translateService.instant(this.translations.art.action_failed),
        '',
        linkMsgComponent?.artFileUnsupportedRef);
      return;
    }
    const addRemoveFormArtPortal = 'art_remove_frm_portal';
    this.loadingService.showLoader(addRemoveFormArtPortal);
    this.artHttpService.addToArtPortal({ artIdList, show }).subscribe(res => {
      this.myArtBulkSelectionService.reset();
      this.loadingService.hideLoader(addRemoveFormArtPortal);
      if (!res) {
        this.notificationService.showNotification(
          NotificationType.ERROR,
          this.translateService.instant(this.translations.art.action_failed),
          '',
          linkMsgComponent?.artFileUnsupportedRef);
        return;
      }
      this.myArtSearchService.refreshArtSection();
      const successMesg = this.getSuccessMessage(show, res);
      this.notificationService.showNotification(NotificationType.SUCCESS, successMesg, '');
    });
  }

  getSuccessMessage(show: boolean, count: number) {
    let message: string;
    if (show) {
      const addMessage = count === 1 ? this.translations.art.file_successfully_added_to_art_portal : this.translations.art.files_successfully_added_to_art_portal;
      message = `${count} ${this.translateService.instant(addMessage)}`;
    } else {
      const removeMessage = count === 1 ? this.translations.art.file_successfully_removed_from_art_portal : this.translations.art.files_successfully_removed_from_art_portal;
      message = `${count} ${this.translateService.instant(removeMessage)}`;
    }
    return message;
  }
}
