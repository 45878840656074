import { Injectable } from '@angular/core';
import { createState, Store, withProps } from '@ngneat/elf';
import { ColorPalette, ColorPaletteView, SystemColor } from '@graphics-flow/types';

export interface ColorPaletteState {
  systemColors: SystemColor[];
  colorPalettes: ColorPalette[];
  colorPaletteActiveView: ColorPaletteView;
  activeColorPalette: ColorPalette;
}

const initialState: ColorPaletteState = {
  systemColors: [],
  colorPalettes: [],
  colorPaletteActiveView: ColorPaletteView.MANAGE_PALETTES,
  activeColorPalette: null
};

const { state, config } = createState(
  withProps<ColorPaletteState>(initialState)
);

@Injectable({ providedIn: 'root' })
export class ColorPaletteStore extends Store {

  constructor() {
    super({ name: 'manag-color-palette', state, config })
  }
}
