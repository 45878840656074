import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs';

import { Translations } from '@graphics-flow/shared/assets';
import { CustomColor, User } from '@graphics-flow/types';
import { UserQuery, UserService } from '@graphics-flow/util';
import { ColorProfileDisclaimerDialogComponent } from '../color-profile-disclaimer-dialog/color-profile-disclaimer-dialog.component';

@Component({
  selector: 'add-art-color',
  templateUrl: './add-art-color.component.html',
  styleUrls: ['./add-art-color.component.scss']
})
export class AddArtColorComponent {

  @Input() color;
  @Input() disabled;
  @Input() isColorPalette = false;
  @Input() showCMYKPicker = false;
  @Output() colorChange: EventEmitter<string> = new EventEmitter();
  @Output() allColorFormat: EventEmitter<Partial<CustomColor>> = new EventEmitter();
  @Output() showCMYKPickerFormat: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public readonly translations: Translations,
    private readonly dialog: MatDialog,
    private readonly userService: UserService,
    private readonly userQuery: UserQuery
  ) { }

  colorPickerClosed(): void {
    this.colorChange.emit(this.color);
  }

  toggleColorPickerType(): void {
    const activeUser: User = this.userQuery.getUser();
    if ((this.showCMYKPicker && activeUser.switchColorProfileDisclaimer.showRGBDisclaimer)
        || (!this.showCMYKPicker && activeUser.switchColorProfileDisclaimer.showCMYKDisclaimer)) {
      this.showColorProfileDisclaimer(activeUser);
      return;
    }

    this.showCMYKPickerFormat.emit(!this.showCMYKPicker);
  }

  showColorProfileDisclaimer(user: User): void {
    this.dialog.open(ColorProfileDisclaimerDialogComponent, {
      data: this.showCMYKPicker,
      closeOnNavigation: true,
      panelClass: 'mobile-screen-modal'
    }).afterClosed().pipe(
      filter((confirmation) => !!confirmation),
      tap((confirmation) => {
        if (confirmation?.dontShowDisclaimer) {
          this.updateUserColorProfileDisclaimer(user);
        }
        if (confirmation?.canSwitch) {
          this.showCMYKPickerFormat.emit(!this.showCMYKPicker);
        }
      })
    ).subscribe();
  }

  updateUserColorProfileDisclaimer(user: User): void {
    if (this.showCMYKPicker) {
      user.switchColorProfileDisclaimer.showRGBDisclaimer = false;
    } else {
      user.switchColorProfileDisclaimer.showCMYKDisclaimer = false;
    }
    this.userService.updateUser(user).subscribe();
  }
}
