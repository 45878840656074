import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { map, Observable } from 'rxjs';
import { ColorPalette, ColorPaletteView, SystemColor } from '@graphics-flow/types';
import { ColorPaletteState, ColorPaletteStore } from './color-palette.store';

type Predicate<T> = (state: ColorPaletteState) => T;

@Injectable({ providedIn: 'root' })
export class ColorPaletteQuery {
  private readonly checkAllHidden = (lists: ColorPalette[]): boolean =>
    lists.filter((colorPalette: ColorPalette) => !colorPalette.isSystemColorPalette)
      .every((colorPalette: ColorPalette) => colorPalette.hide);

  systemColors$: Observable<SystemColor[]> = this.selectStateProps<SystemColor[]>((state) => state.systemColors);
  colorPalettes$: Observable<ColorPalette[]> = this.selectStateProps<ColorPalette[]>((state) => state.colorPalettes);
  hasCustomColorPalette$: Observable<boolean> = this.colorPalettes$.pipe(
    map((colorPalettes: ColorPalette[]) => {
      return colorPalettes.length > 1;
    })
  );
  colorPaletteActiveView$: Observable<ColorPaletteView> = this.selectStateProps<ColorPaletteView>((state) => state.colorPaletteActiveView);
  activeColorPalette$: Observable<ColorPalette> = this.selectStateProps<ColorPalette>((state) => state.activeColorPalette);
  isAllCustomPalettesHidden$: Observable<boolean> = this.colorPalettes$.pipe(
    map(this.checkAllHidden)
  );

  learnMoreAboutRGBAndCMYKLink: string = 'https://graphicsflow.zendesk.com/hc/en-us/articles/30902387316379-Understanding-Pantone-Spot-Colors-CMYK-Hex-and-RGB-Color-Models';

  constructor(private store: ColorPaletteStore) {}

  selectStateProps<T>(predicate: Predicate<T>): Observable<T> {
    return this.store.pipe(select(predicate));
  }

  getValue(): ColorPaletteState {
    return this.store?.getValue();
  }

  getActiveColorPalette(): ColorPalette {
    return this.getValue().activeColorPalette;
  }

  getColorPalettes(): ColorPalette[] {
    return this.getValue().colorPalettes;
  }

  getSystemPalette(): ColorPalette {
    return this.getValue().colorPalettes.find((palette: ColorPalette) => palette.isSystemColorPalette);
  }

  isAllCustomPalettesHidden(): boolean {
    const palettes = this.getColorPalettes();
    return this.checkAllHidden(palettes);
  }
}
