import { ColorPalette, ID } from '@graphics-flow/types';


export class ColorPaletteHelper {

  static generateColorPaletteForSave(colorPalette: ColorPalette, updatedColorPalette: ColorPalette): ColorPalette {
    return Object.assign(colorPalette, updatedColorPalette);
  }

  static removeUserFromShowingWarning(usersToShowWarning: ID[], userToBeRemoveDFromWarning: ID): ID[] {
    return usersToShowWarning?.filter(userId => userId !== userToBeRemoveDFromWarning);
  }
}
