import { Pipe, PipeTransform } from '@angular/core';
import { isEqual as _isEqual } from 'lodash-es';
import { CustomColor } from '@graphics-flow/types';

@Pipe({
  name: 'isPaletteColorEqualsSelectedColor'
})
export class IsPaletteColorEqualsSelectedColorPipe implements PipeTransform {
  transform(selectedColor: CustomColor, paletteColor: CustomColor): boolean {
    // This code snippet will try to add '#' to hex, when it doesn't have it.
    const paletteHexValue = `#${paletteColor.hexValue.replace(/^#/, '')}`;

    if (selectedColor?.isCMYK && paletteColor?.isCMYK) {
      return selectedColor?.name === paletteColor?.name && _isEqual(selectedColor?.cmyk, paletteColor?.cmyk);
    }
    return selectedColor?.name === paletteColor?.name && selectedColor?.hexValue === paletteHexValue && (!selectedColor?.isCMYK && !paletteColor?.isCMYK);
  }
}
