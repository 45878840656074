<div id="colorProfileDisclaimerDialog">
  <div class="cell shrink" mat-dialog-title>
    <h2 id="colorProfileDisclaimerLabel" class="flex-child-shrink m-b-0 t-20-500-p">{{ translations.organization.tab.color_palettes_management.color_profile_disclaimer.label | translate }}</h2>
  </div>
  <mat-dialog-content>
    <p id="colorProfileDisclaimerDescription" class="t-16-400-p color-profile-disclaimer-description">
      <span [innerHTML]="translations.organization.tab.color_palettes_management.color_profile_disclaimer.disclaimer_desc_1_x | translate: { color: (isCMYK ? translations.common.colors.rgb : translations.common.colors.cmyk) | translate }"></span>
      {{ (isCMYK ? translations.organization.tab.color_palettes_management.color_profile_disclaimer.disclaimer_cmyk_to_rgb : translations.organization.tab.color_palettes_management.color_profile_disclaimer.disclaimer_rgb_to_cmyk) | translate}}
      <a id="colorProfileHelpLink" class="link-color cursor-pointer text-decoration-none" [href]="colorPaletteQuery.learnMoreAboutRGBAndCMYKLink" target="_blank">{{ translations.organization.tab.color_palettes_management.manage_color_palettes_link | translate }}</a>
    </p>
    <div id="colorProfileDisclaimerConfirmation" class="t-16-500-p color-profile-disclaimer-confirmation">{{ translations.organization.tab.color_palettes_management.color_profile_disclaimer.disclaimer_confimation_x | translate: { color: (isCMYK ? translations.common.colors.rgb : translations.common.colors.cmyk) | translate } }}</div>
    <div id="dontShowMessageContainer" class="t-16-400-p dont-show-message-container">
      <mat-checkbox id="dontShowMessageCheckbox" class="dont-show-me" [(ngModel)]="dontShowMeAgain">{{ translations.common.dont_show_message_again | translate }}</mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right full-width-buttons-mobile">
    <button mat-raised-button
      id="cancelBtn"
      color="secondary"
      class="large"
      (click)="close()">
      {{ translations.common.cancel | translate}}
    </button>
    <button mat-raised-button
      id="switchColorProfileBtn"
      color="primary"
      class="large"
      (click)="switchTheColorProfile()">
      {{ ( isCMYK
        ? translations.organization.tab.color_palettes_management.switch_to_rgb
        : translations.organization.tab.color_palettes_management.switch_to_cmyk ) | translate
      }}
    </button>
  </mat-dialog-actions>
</div>
