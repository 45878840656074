import { Injectable } from '@angular/core';
import { getAllEntitiesApply, getEntity, selectAllEntities, selectAllEntitiesApply, selectEntity } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IAssociatedOrganization, ID, IRoles, Organization, User } from '@graphics-flow/types';

import { OrganizationQuery } from '../organization/organization.query';
import { TeamStore } from './team.store';

@Injectable({ providedIn: 'root' })
export class TeamQuery {
  team$: Observable<User[]> = this.selectAllUserEntities();

  activeTeam$: Observable<User[]> = this.selectAllUserEntitiesByApply({
    filterEntity: (state) => !state.deleted
  });

  owner$: Observable<User> = this.activeTeam$.pipe(
    map((users) => users.find((user) => user?.associatedOrganizations[0]?.role === IRoles.Owner))
  );

  constructor(
    protected store: TeamStore,
    protected organizationQuery: OrganizationQuery
  ) {
  }

  selectUserEntity(id: ID): Observable<User> {
    return this.store.pipe(selectEntity(id));
  }

  selectAllUserEntities(): Observable<User[]> {
    return this.store.pipe(selectAllEntities());
  }

  selectAllUserEntitiesByApply(predicate): Observable<User[]> {
    return this.store.pipe(selectAllEntitiesApply(predicate));
  }

  getAllUserEntitiesApply(predicate): User[] {
    return this.store.query(getAllEntitiesApply(predicate));
  }

  getActiveUsers(): User[] {
    return this.getAllUserEntitiesApply({
      filterEntity: (state) => !state.deleted
    });
  }

  getUserEntity(id: ID): User {
    return this.store.query(getEntity(id));
  }

  getUserCurrentOrganizationRole(userId: ID): Observable<IRoles> {
    return this.selectUserEntity(userId).pipe(
      filter((user) => !!user),
      map((user: User) => {
        return this.getUserRole(user);
      })
    );
  }

  getUserRole(user: User): IRoles {
    const organization: Organization = this.organizationQuery.getActive();
    const associatedOrganization = user?.associatedOrganizations?.find((org: IAssociatedOrganization) => org.organizationId === organization.organizationId);
    return associatedOrganization?.role;
  }
}
