import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { UserHttpService } from '@graphics-flow/api';
import { PendoData, PendoMetaData } from '@graphics-flow/types';

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  private pendo: any;

  constructor(
    private readonly userHttpService: UserHttpService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  initiatePendo(): void {
    this.getPendoScript().pipe(
      tap((data: PendoMetaData) => {
        this.pendo.initialize(data);
      })
    ).subscribe();
  }

  getPendoScript(): Observable<PendoMetaData> {
    return this.userHttpService.getPendoScript().pipe(
      map(({ script1, script2 }: PendoData) => {
        this.loadScript(script1);
        return script2;
      })
    );
  }

  loadScript(pendoScriptContent: string): void {
    const element = this.document.createElement('script');
    element.id = 'script-pendo';
    element.type = 'text/javascript';
    element.text = pendoScriptContent;
    element.async = true;
    this.document.head.appendChild(element);
    this.pendo = (window as any).pendo;
  }

  reset(): void {
    delete (window as any).pendo;
    this.pendo = null;
    const pendoElement = this.document.getElementById('script-pendo');
    if (pendoElement) {
      pendoElement.remove();
    }
  }
}
