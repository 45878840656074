import { Injectable } from '@angular/core';
import { setProp, setProps } from '@ngneat/elf';
import { Observable, tap } from 'rxjs';
import { CustomizeStockArtHttpService } from '@graphics-flow/api';
import { ColorPalette, ColorPaletteView, ID, SystemColor } from '@graphics-flow/types';
import { ColorPaletteStore } from './color-palette.store';

@Injectable({
  providedIn: 'root'
})
export class ColorPaletteService {
  constructor(
    private readonly colorPaletteStore: ColorPaletteStore,
    private readonly customizeStockArtHttpService: CustomizeStockArtHttpService
  ) {}

  updateManageColorPalette<T>(key: string, value: T): void {
    this.colorPaletteStore.update(setProps({ [key]: value }));
  }

  getSystemColorPalette(): Observable<SystemColor[]>  {
    return this.customizeStockArtHttpService.getColors().pipe(
      tap((data: SystemColor[]) => this.updateManageColorPalette<SystemColor[]>('systemColors', data))
    );
  }

  getColorPalettes(): Observable<ColorPalette[]> {
    return this.customizeStockArtHttpService.getColorPalettes().pipe(
      tap((data: ColorPalette[]) => this.updateManageColorPalette<ColorPalette[]>('colorPalettes', data))
    );
  }

  setColorPaletteActiveView(viewType: ColorPaletteView): void {
    this.updateManageColorPalette<ColorPaletteView>('colorPaletteActiveView', viewType);
  }

  setActiveColorPalette(colorPalette?: ColorPalette): void {
    this.updateManageColorPalette<ColorPalette>('activeColorPalette', colorPalette);
  }

  createNewColorPalette(colorPalette: ColorPalette): Observable<ColorPalette> {
    return this.customizeStockArtHttpService.createColorPalette(colorPalette).pipe(
      tap((newColorPalette) => {
        this.colorPaletteStore.update((state)=> ({
          ...state,
          colorPalettes: [newColorPalette, ...state.colorPalettes]
        }));
      })
    );
  }

  updateColorPalette(colorPalette: ColorPalette): Observable<ColorPalette> {
    return this.customizeStockArtHttpService.updateColorPalette(colorPalette).pipe(
      tap((updatedPalette: ColorPalette) => {
        this.colorPaletteStore.update(setProp('colorPalettes', (colorPalettes: ColorPalette[]) => {
          const updatedColorPalettes = colorPalettes.map((palette: ColorPalette) => palette.colorPaletteId === colorPalette.colorPaletteId ? updatedPalette : palette );
          return updatedColorPalettes;
        }));
      })
    );
  }

  deleteColorPalette(colorPaletteId: ID): Observable<boolean> {
    return this.customizeStockArtHttpService.deleteColorPalette(colorPaletteId).pipe(
      tap(() => {
        this.colorPaletteStore.update(setProp('colorPalettes', (colorPalettes: ColorPalette[]) => {
          const updatedColorPalettes = colorPalettes.filter((palette: ColorPalette) => palette.colorPaletteId !== colorPaletteId);
          return updatedColorPalettes;
        }));
      })
    );
  }

  sortColorPalette(colorPaletteId: ID, sortOrder: number): Observable< ColorPalette[]>  {
    return this.customizeStockArtHttpService.sortColorPalette(colorPaletteId, sortOrder).pipe(
      tap((colorPalettes: ColorPalette[]) => this.updateManageColorPalette<ColorPalette[]>('colorPalettes', colorPalettes))
    );
  }
}
